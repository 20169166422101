import React, { useState } from "react";
import StakingFixed from "./StakingFixed";
import StakingFlexibale from "./StakingFlexibale";
import WithdrawalFixed from "./WithdrawalFixed";
import WithdrawalFlexibale from "./WithdrawalFlexibale";

const Withdrawals = () => {
  const [tab, setTab] = useState("fixed");
  return (
    <div className="tab-pane fade active show" id="on-sale" role="tabpanel" aria-labelledby="on-sale-tab">
      {/* Filters */}
      <div className="mb-8 flex flex-wrap items-center justify-center">
        <ul className="flex flex-wrap items-center">
          <li className="my-1 mr-2.5" onClick={() => setTab("fixed")}>
            <button
              className={`${
                tab === "fixed" ? "tab-active" : ""
              } group flex h-9 items-center custom-btn rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white`}
            >
              <span>Fixed</span>
            </button>
          </li>
          <li className="my-1 mr-2.5" onClick={() => setTab("flexibale")}>
            <button
              className={`${
                tab === "flexibale" ? "tab-active" : ""
              } group flex h-9 items-center custom-btn rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white`}
            >
              <span>Flexible</span>
            </button>
          </li>
        </ul>
      </div>
      {tab === "fixed" ? <WithdrawalFixed /> : tab === "flexibale" ? <WithdrawalFlexibale /> : ""}
    </div>
  );
};

export default Withdrawals;
