import { useState, useEffect, useContext } from "react";
import { onmaxContext } from "../../../OnmaxContext";
import React from "react";
import Web3 from "web3";
import Tokens from "../../Comman/Tokens.json";
import Copy from "../../Comman/Copy";
import Spinner from "../../Comman/Spinner";
import OnmaxSpinner from "../../ContentLoader/OnmaxSpinner";

const TotalStakingFixed = ({ mainTab, tab }) => {
  const [tokens, setTokens] = useState(null);
  const { formatAddress, formatDate, getWeb3ContractFixed, connAddress } = useContext(onmaxContext);
  const [dataSpinner, setDataSpinner] = useState(true);

  const resetTokensData = () => {
    try {
      let tokenscpy = [...Tokens];
      for (let i = 0; i < tokenscpy.length; i++) {
        tokenscpy[i].totalstake = 0;
        tokenscpy[i].totalstakeLoader = true;
      }
      getTotalStaking();
    } catch (err) {
      console.log("error in resetTokensData : ", err);
    }
  };

  const getTotalStaking = async () => {
    try {
      if (Tokens.length) {
        let tokenscpy = [...Tokens];
        const contract = await getWeb3ContractFixed();
        for (let i = 0; i < tokenscpy.length; i++) {
          const totalStakingWei = await contract.methods.getTotalAddressFarmingOfToken(tokenscpy[i].address, connAddress).call();
          const totalStaking = await Web3.utils.fromWei(totalStakingWei, "ether");
          tokenscpy[i].totalstake = totalStaking;
          tokenscpy[i].totalstakeLoader = false;
        }
        setTokens([...tokenscpy]);
      }
    } catch (err) {
      console.log("error in getApy : ", err);
    }
  };

  useEffect(() => {
    setTokens([...Tokens]);
    setDataSpinner(false);
    resetTokensData();
  }, [connAddress, mainTab, tab]);

  return (
    <>
      {dataSpinner ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
          {tokens?.length ? (
            tokens?.map((item, index) => {
              return (
                <article key={index}>
                  <div className="block  border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                    <figure className="relative">
                      <div className=" sm:pr-4 lg:pr-8">
                        <div className="mt-3 flex">
                          <figure className="mr-4 shrink-0">
                            {item.icon ? (
                              <a href="" className="relative block">
                                <img src={item.icon} width="60" alt="avatar" className="rounded-2lg" loading="lazy" />
                              </a>
                            ) : (
                              ""
                            )}
                          </figure>
                          <div>
                            <div className="flex items-center whitespace-nowrap">
                              <span className="text-lg font-medium leading-tight tracking-tight text-green flex">
                                {item.totalstakeLoader ? <OnmaxSpinner /> : item.totalstake > 0 ? item.totalstake : 0} {item.symbol}
                              </span>
                            </div>
                            <span className="text-sm text-jacarta-400 dark:text-jacarta-300 flex">
                              {formatAddress(item.address)} <Copy text={item.address}></Copy>
                            </span>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                </article>
              );
            })
          ) : (
            <>
              <h2 className="text-center text-white"></h2>
              <h2 className="text-center text-white">No data found.</h2>
              <h2 className="text-center text-white"></h2>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TotalStakingFixed;
