import React, { useContext, useEffect, useState } from "react";
import { onmaxContext } from "../../../OnmaxContext";
import Pagination from "../../Comman/Pagination";
import Web3 from "web3";
import Copy from "../../Comman/Copy";
import Spinner from "../../Comman/Spinner";

const WithdrawalFixed = () => {
  const { connAddress, getWeb3ContractFixed, formatAddress, formatDate } = useContext(onmaxContext);
  const [withdrawalsAll, setWithdrawalsAll] = useState(null);
  const [withdrawals, setWithdrawals] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [pageSize, setP0ageSize] = useState(10);

  const [dataSpinner, setDataSpinner] = useState(true);

  const getWithdrawals = async () => {
    try {
      setDataSpinner(true);
      const contract = await getWeb3ContractFixed();
      const withdrawalsArr = await contract.methods.getHarvestOfAddress(connAddress).call();
      await withdrawalsArr.reverse();
      const len = withdrawalsArr.length;
      const pages = Math.ceil(len / pageSize);
      setPages(pages);
      setWithdrawalsAll(withdrawalsArr);
      const skip = pageSize * (currentPage - 1);
      const slice = withdrawalsArr.splice(skip, pageSize);
      setWithdrawals([...slice]);
      setDataSpinner(false);
    } catch (err) {
      console.log("error in getWithdrawals :", err);
    }
  };

  const getWithdrawalsPaginate = async () => {
    try {
      if (withdrawalsAll?.length) {
        setDataSpinner(true);
        const withcpy = [...withdrawalsAll];
        const skip = pageSize * (currentPage - 1);
        const slice = withcpy.splice(skip, pageSize);
        setWithdrawals([...slice]);
        setDataSpinner(false);
      }
    } catch (err) {
      console.log("error in getWithdrawals :", err);
    }
  };

  useEffect(() => {
    if (connAddress) {
      getWithdrawals();
    }
  }, [connAddress]);

  useEffect(() => {
    getWithdrawalsPaginate();
  }, [currentPage, pageSize]);

  return (
    <>
      {dataSpinner ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="scrollbar-custom overflow-x-auto">
          <div
            role="table"
            className="w-full min-w-[736px] border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white lg:rounded-2lg"
          >
            <div className="flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600" role="row">
              <div className="w-[25%] py-3 px-4" role="columnheader">
                <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">Sr.</span>
              </div>
              <div className="w-[28%] py-3 px-4" role="columnheader">
                <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">User</span>
              </div>
              <div className="w-[28%] py-3 px-4" role="columnheader">
                <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">Token</span>
              </div>
              <div className="w-[28%] py-3 px-4" role="columnheader">
                <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">Harvest</span>
              </div>
              <div className="w-[28%] py-3 px-4" role="columnheader">
                <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">Date</span>
              </div>
            </div>
            {withdrawals && withdrawals?.length ? (
              withdrawals.map((item, index) => {
                const ind = pageSize * (currentPage - 1) + 1;
                const val = Web3.utils.fromWei(item.value, "ether");
                return (
                  <a type="button" className="flex transition-shadow hover:shadow-lg" role="row" key={index}>
                    {/* <div
                  className="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                  role="cell"
                >
                  <span className="mr-2 lg:mr-4">{ind}</span>
                  <figure className="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                    <img src="assets/img/avatars/avatar_1.jpg" alt="avatar 1" className="rounded-2lg" loading="lazy" />
                    <div
                      className="absolute -right-2 -bottom-1 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                      data-tippy-content="Verified Collection"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-[.875rem] w-[.875rem] fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                      </svg>
                    </div>
                  </figure>
                  <span className="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                    {formatAddress(item.id)}
                  </span>
                </div> */}
                    <div className="flex w-[25%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600" role="cell">
                      <span className="mr-2 lg:mr-4">{ind}</span>
                    </div>
                    <div className="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600" role="cell">
                      <span className="font-display font-semibold text-jacarta-700 dark:text-white flex">
                        {formatAddress(item.user)} <Copy text={item.user} />
                      </span>
                    </div>
                    <div className="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600" role="cell">
                      <span className="font-display font-semibold text-jacarta-700 dark:text-white flex">
                        {formatAddress(item.token)} <Copy text={item.token} />
                      </span>
                    </div>
                    <div className="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600" role="cell">
                      <span className="font-display font-semibold text-jacarta-700 dark:text-white">{Number(val).toFixed(5)} ONX</span>
                    </div>
                    <div className="flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600" role="cell">
                      <span className="font-display font-semibold text-jacarta-700 dark:text-white">
                        {formatDate(Number(item.datetime) * 1000)}
                      </span>
                    </div>
                  </a>
                );
              })
            ) : (
              <a type="button" className="transition-shadow hover:shadow-lg w-full text-center p-4" role="row">
                Data not found.
              </a>
            )}
          </div>
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} pages={pages} />
        </div>
      )}
    </>
  );
};

export default WithdrawalFixed;
