import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import Web3 from "web3";

import TokenABI from "./Components/abis/tokenAbi.json";
import ContractFixedABI from "./Components/abis/contractFixed.json";
import ContractFlexibaleABI from "./Components/abis/contractFlexibale.json";
import ContractPancake from "./Components/abis/contractPancake.json";

const web3 = new Web3(process.env.REACT_APP_HTTP_RPC);

export const onmaxContext = createContext();

// 1. Get projectId
const projectId = process.env.REACT_APP_PROJECT_ID;
// 2. Set chains
const mainnet = {
  chainId: 56,
  name: "BNB Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: process.env.REACT_APP_HTTP_RPC,
};

// 3. Create modal
const metadata = {
  name: "Onmax Staking",
  description: "Onmax Staking",
  url: "https://app.onmaxyield.farm", // origin must match your domain & subdomain
  icons: ["https://yeild.xeer.store/assets/img/token/onmx.png"],
};

try {
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    defaultChain: 97,
  });
} catch (err) {}

function OnmaxContext({ children }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open, close } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const [connAddress, setConnAddress] = useState("");
  const [etherProvider, setEtherProvider] = useState(null);
  const [etherSigner, setEtherSigner] = useState(null);
  const [balance, setBalance] = useState(0);
  const [joiningDate, setJoiningDate] = useState("");

  const setProvider = async () => {
    try {
      if (walletProvider) {
        const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await ethersProvider.getSigner();
        setEtherProvider(ethers);
        setEtherSigner(signer);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  // const getBalanceBNB = async () => {
  //   try {
  //     if (connAddress) {
  //       const balwei = await web3.eth.getBalance(connAddress);
  //       const bal = Number(web3.utils.fromWei(balwei, "ether")).toFixed(2);
  //       setBalance(bal);
  //     } else {
  //       return false;
  //     }
  //   } catch (err) {
  //     console.log("error in getBalanceBNB : ", err);
  //   }
  // };

  // useEffect(() => {
  //   if (web3) {
  //     getBalanceBNB();
  //   }
  // }, [web3, connAddress]);

  useEffect(() => {
    if (ethers) {
      setProvider();
    }
  }, [ethers, address]);

  const connectWallet = () => {
    try {
      open();
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (address) {
      setConnAddress(address);
    } else {
      setConnAddress("");
    }
  }, [address]);

  const formatAddress = (text) => {
    if (text) {
      return text.substr(0, 5) + "..." + text.substr(-5, 5);
    } else {
      return "";
    }
  };

  const formatDate = (date) => {
    if (date) {
      const newdate = new Date(date);
      const day = newdate.getDate();
      const month = newdate.getMonth() + 1;
      const year = newdate.getFullYear();
      return `${day.toString().padStart(2, "0")}-${month.toString().padStart(2, "0")}-${year}`;
    } else {
      return "";
    }
  };

  const getWeb3ContractFlexibale = async () => {
    try {
      const contract = await new web3.eth.Contract(ContractFlexibaleABI, process.env.REACT_APP_CONTRACT_FLEXIBALE);
      return await contract;
    } catch (err) {
      console.log("error in getWeb3ContractFlexibale : ", err);
    }
  };
  const getEtherContractFlexibale = async () => {
    try {
      const contract = await new etherProvider.Contract(process.env.REACT_APP_CONTRACT_FLEXIBALE, ContractFlexibaleABI, etherSigner);
      return await contract;
    } catch (err) {
      console.log("error in getWeb3ContractFlexibale : ", err);
    }
  };

  const getWeb3ContractPancakeFactory = async () => {
    try {
      const contract = await new web3.eth.Contract(ContractPancake, process.env.REACT_APP_PANCACKE_FACTORY);
      return await contract;
    } catch (err) {
      console.log("error in getWeb3ContractFixed : ", err);
    }
  };

  const getWeb3ContractFixed = async () => {
    try {
      const contract = await new web3.eth.Contract(ContractFixedABI, process.env.REACT_APP_CONTRACT_FIXED);
      // const contract = await new web3.eth.Contract(ContractFixedABI, "0x0bE41BDdc426963B5f72a3dfD757aE2FA20039Fc");
      return await contract;
    } catch (err) {
      console.log("error in getWeb3ContractFixed : ", err);
    }
  };

  const getEtherContractFixed = async () => {
    try {
      const contract = await new etherProvider.Contract(process.env.REACT_APP_CONTRACT_FIXED, ContractFixedABI, etherSigner);
      return await contract;
    } catch (err) {
      console.log("error in getWeb3ContractFlexibale : ", err);
    }
  };

  const getWeb3TokenContract = async (token) => {
    try {
      const contract = await new web3.eth.Contract(TokenABI, token);
      return await contract;
    } catch (err) {
      console.log("error in getWeb3TokenContract : ", err);
    }
  };

  const getbalance = async (token, address, fix = 4) => {
    try {
      const contract = await new web3.eth.Contract(ContractFlexibaleABI, token);
      const balwei = await contract.methods.balanceOf(address).call();
      const bal = await web3.utils.fromWei(balwei, "ether");
      return Number(bal).toFixed(fix);
    } catch (err) {
      console.log("error in getbalance : ", err);
    }
  };

  const checkAllowance = async (token, address, contractadd, value) => {
    try {
      const contract = await new web3.eth.Contract(TokenABI, token);
      const allowwei = await contract.methods.allowance(address, contractadd).call();
      const allow = Number(await web3.utils.fromWei(allowwei, "ether"));
      return value <= allow;
    } catch (err) {
      console.log("error in checkAllowance : ", err);
      return false;
    }
  };

  const giveAllowance = async (token, contractadd) => {
    try {
      if (etherProvider && etherSigner) {
        const contract = await new etherProvider.Contract(token, TokenABI, etherSigner);
        const totalsupply = await contract.totalSupply();
        const tx = await contract.approve(contractadd, totalsupply.toString());
        await tx.wait();
        if (tx?.hash) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (err) {
      console.log("error in giveAllowance : ", err);
      return false;
    }
  };

  const toastSuccess = (text, time = 3000) => {
    toast.success(text, {
      position: "top-left",
      autoClose: time,
    });
  };
  const toastError = (text, time = 3000) => {
    toast.error(text, {
      position: "top-left",
      autoClose: time,
    });
  };

  const getTokenBalance = async (token, fixed = 2) => {
    try {
      if (connAddress) {
        const contract = await getWeb3TokenContract(token);
        const balwei = await contract.methods.balanceOf(connAddress).call();
        const bal = Number(await web3.utils.fromWei(balwei, "ether")).toFixed(fixed);
        return bal;
      }
    } catch (err) {
      console.log("error in getTokenBalance : ", err);
    }
  };

  function delay(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const checkHashStatusIsComplete = async (hash, noOfTime = 25) => {
    for (let i = 0; i < noOfTime && i < noOfTime; i++) {
      try {
        let trx = await web3.eth.getTransactionReceipt(hash);
        if (trx) {
          await delay(2000);
          return { status: true };
        }
      } catch (err) {
        if (err.message === "Transaction not found") {
        } else {
          return { status: false, error: err.message };
        }
      }
      await delay(3000);
    }
  };

  const getFullMonthName = (month) => {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return "";
    }
  };

  return (
    <onmaxContext.Provider
      value={{
        connectWallet,
        disconnect,
        connAddress,
        isConnected,
        formatAddress,
        getWeb3ContractFlexibale,
        getWeb3ContractFixed,
        getWeb3TokenContract,
        getbalance,
        checkAllowance,
        balance,
        // getBalanceBNB,
        giveAllowance,
        getEtherContractFlexibale,
        getEtherContractFixed,
        toastSuccess,
        toastError,
        formatDate,
        getTokenBalance,
        joiningDate,
        setJoiningDate,
        getFullMonthName,
        getWeb3ContractPancakeFactory,
      }}
    >
      {children}
    </onmaxContext.Provider>
  );
}

export default OnmaxContext;
