import React, { useContext, useEffect, useState } from "react";
import { onmaxContext } from "../../OnmaxContext";
import Web3 from "web3";
import Spinner from "../Comman/Spinner";
import Copy from "../Comman/Copy";
import Tokens from "../Comman/Tokens.json";
import OnmaxSpinner from "../ContentLoader/OnmaxSpinner";
import { ethers } from "ethers";
// import SimpleBox from "../ContentLoader/SimpleBox";

export default function Fixed({ tab }) {
  const {
    getEtherContractFixed,
    toastSuccess,
    toastError,
    // getBalanceBNB,
    giveAllowance,
    checkAllowance,
    connAddress,
    formatAddress,
    getWeb3TokenContract,
    getWeb3ContractFixed,
    getTokenBalance,
  } = useContext(onmaxContext);
  const [selectedToken, setSelectedToken] = useState(null);
  const [stakeModal, setStakeModal] = useState(false);
  const [selecteMonth, setSelecteMonth] = useState(0);
  const [amount, setAmount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
  const [hash, setHash] = useState("");

  const [tokens, setTokens] = useState(null);
  const [dataSpinner, setDataSpinner] = useState(true);
  const [rewardInfo, setRewardInfo] = useState({
    withdrawalDailyPer: 0,
    withdrawalDailyReward: 0,
  });

  const [rewardInfoSpinner, setRewardInfoSpinner] = useState(false);

  const setTokenBalance = async () => {
    const bal = await getTokenBalance(selectedToken.address);
    setSelectedTokenBalance(bal);
  };

  useEffect(() => {
    if (selectedToken && connAddress) {
      setTokenBalance();
    } else {
      setSelectedTokenBalance(0);
    }
  }, [selectedToken]);

  const getApy = async () => {
    try {
      if (Tokens.length) {
        setDataSpinner(true);
        let tokenscpy = Tokens.slice(0);
        const lockmonth = ["3", "6", "9", "12", "18", "24"];
        const contract = await getWeb3ContractFixed();
        const apyper = await contract.methods.getAllLockTimePer().call();
        for (let i = 0; i < tokenscpy.length; i++) {
          let defaultApy = [
            { month: 3, apyper: 0 },
            { month: 6, apyper: 0 },
            { month: 9, apyper: 0 },
            { month: 12, apyper: 0 },
            { month: 18, apyper: 0 },
            { month: 24, apyper: 0 },
          ];
          for (let j = 0; j < apyper.length; j++) {
            if (tokenscpy[i].address.toLowerCase() === apyper[j].token.toLowerCase() && apyper[j].status) {
              const ind = lockmonth.findIndex((item) => item === apyper[j].month.toString());
              defaultApy[ind].apyper = Number(Web3.utils.fromWei(apyper[j].apy, "ether"));
            }
          }
          tokenscpy[i].apy = [...defaultApy];
          tokenscpy[i].apyLoader = false;
          tokenscpy[i].balanceLoader = true;
          tokenscpy[i].totalstakeLoader = true;
        }
        setTokens([...tokenscpy]);
        setDataSpinner(false);
        getTokenLockValue();
      }
    } catch (err) {
      console.log("error in getApy : ", err);
    }
  };

  const getTokenLockValue = async () => {
    try {
      if (Tokens.length) {
        let tokenscpy = Tokens.slice(0);
        const contract = await getWeb3ContractFixed();
        for (let i = 0; i < tokenscpy.length; i++) {
          const stakewei = await contract.methods.getTotalFarmingOfToken(tokenscpy[i].address).call();
          const totalstake = Number(await Web3.utils.fromWei(stakewei, "ether")).toFixed(2);
          tokenscpy[i].totalstake = totalstake;
          tokenscpy[i].totalstakeLoader = false;
        }
        setTokens([...tokenscpy]);
      }
    } catch (err) {
      console.log("error in getTokenLockValue : ", err);
    }
  };

  useEffect(() => {
    setTokens([]);
    getApy();
  }, [connAddress, tab]);

  const setTokenData = (data) => {
    setSelectedToken(data);
    setStakeModal(true);
  };

  const closeStakeModal = () => {
    setSelectedToken(null);
    setAmount(0);
    setSpinner(false);
    setSelecteMonth(0);
    setStakeModal(false);
    setHash("");
  };

  const stake = async () => {
    try {
      setSpinner(true);
      setHash("");
      if (amount > 0) {
        if (amount <= Number(selectedTokenBalance)) {
          if (selecteMonth) {
            let temp = true;
            const contract = await getEtherContractFixed();
            const amtWei = Web3.utils.toWei(amount, "ether");
            const res = await checkAllowance(selectedToken?.address, connAddress, process.env.REACT_APP_CONTRACT_FIXED, amount);
            if (!res) {
              const data = await giveAllowance(selectedToken?.address, process.env.REACT_APP_CONTRACT_FIXED);
              if (!data) {
                temp = false;
                toastError("Something went wrong.");
                setSpinner(false);
              }
            }
            if (temp) {
              const tx = await contract.farm(selectedToken?.address, selecteMonth.toString(), amtWei);
              let recipt = await tx.wait();
              if (recipt?.transactionHash) {
                toastSuccess("Farm successfully.");
                setHash(recipt?.transactionHash);
                // getBalanceBNB();
                getTokenLockValue();
                setSelecteMonth(0);
                setAmount("0");
                // getApy();
                setSpinner(false);
              }
            }
          } else {
            toastError("Select locktime.");
            setSpinner(false);
          }
        } else {
          toastError("Insufficient funds.");
          setSpinner(false);
        }
      } else {
        toastError("Amount must be greaterthan 0.");
        setSpinner(false);
      }
    } catch (err) {
      console.log("error in stake : ", err);
      toastError("Something went wrong.");
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (Number(selecteMonth) !== 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [selecteMonth]);

  const getWithdrawalData = async () => {
    try {
      setRewardInfoSpinner(true);
      const contract = await getWeb3ContractFixed();
      const data = await contract.methods
        .getFarmingRewardInfoBeforeFarm(
          selectedToken?.address?.toString(),
          selecteMonth?.toString(),
          ethers.utils.parseUnits(amount?.toString(), "18")?.toString()
        )
        .call();
      setRewardInfo({
        withdrawalDailyPer: Number(Web3.utils.fromWei(data.dailyPer, "ether")).toFixed(6),
        withdrawalDailyReward: Number(Web3.utils.fromWei(data.dailyReward, "ether")).toFixed(6),
      });
      setRewardInfoSpinner(false);
    } catch (err) {
      console.log("error in getWtihdrawalData : ", err);
      setRewardInfoSpinner(false);
    }
  };

  useEffect(() => {
    if (Number(selecteMonth) > 0 && selectedToken && Number(amount)) {
      getWithdrawalData();
    }
  }, [selecteMonth, selectedToken, amount]);

  return (
    <>
      {/* Grid */}
      {dataSpinner ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
          {tokens?.length ? (
            tokens?.map((item, index) => {
              return (
                <article key={index}>
                  <div className="block  border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                    <figure className="relative">
                      <div className=" sm:pr-4 lg:pr-8">
                        <div className="mt-3 flex">
                          <figure className="mr-4 shrink-0">
                            {item.icon ? (
                              <a href="" className="relative block">
                                <img src={item.icon} width="60" alt="avatar" className="rounded-2lg" loading="lazy" />
                              </a>
                            ) : (
                              ""
                            )}
                          </figure>
                          <div>
                            <div className="flex items-center whitespace-nowrap">
                              <span className="text-lg font-medium leading-tight tracking-tight text-green flex">
                                {item.totalstakeLoader ? <OnmaxSpinner /> : item?.totalstake > 0 ? item?.totalstake : 0} {item.symbol}
                              </span>
                            </div>
                            <span className="text-sm text-jacarta-400 dark:text-jacarta-300 flex">
                              {formatAddress(item.address)} <Copy text={item.address}></Copy>
                            </span>
                          </div>
                        </div>
                      </div>
                    </figure>
                    <div className="mt-7 flex items-center justify-between">
                      <span className="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">{item.name}</span>
                    </div>
                    <div className="mt-2 text-sm">
                      <span className="text-jacarta-700 dark:text-jacarta-100">
                        {/* {item.apyLoader ? "..." : item.apy?.length ? item.apy[item.apy.length - 1]?.month : ""} month{" "}
                        {item.apy?.length ? item.apy[item.apy.length - 1]?.apyper : ""} % */}
                        {item.apyLoader
                          ? "..."
                          : item.apy?.length
                          ? item.apy
                              .filter((el) => el.apyper > 0)
                              .map((subel, index) => {
                                return (
                                  <p>
                                    {subel?.month} month {subel.apyper}% APY
                                  </p>
                                );
                              })
                          : ""}
                      </span>
                    </div>
                    <div className="mt-2 text-sm"></div>
                    <div className="mt-8 flex items-center justify-between">
                      <button
                        className="inline-block w-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark cursor-pointer"
                        onClick={() => setTokenData(item)}
                        disabled={item?.apy?.length ? false : true}
                      >
                        Farm
                      </button>
                    </div>
                  </div>
                </article>
              );
            })
          ) : (
            <>
              <h2 className="text-center text-white"></h2>
              <h2 className="text-center text-white">No data found.</h2>
              <h2 className="text-center text-white"></h2>
            </>
          )}
        </div>
      )}
      <div
        className={`modal fade ${
          stakeModal ? "show" : ""
        } block border border-jacarta-100 bg-white p-1 transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700`}
        id="placeBidModal"
        tabIndex={-1}
        aria-labelledby="placeBidLabel"
        style={stakeModal ? { display: "block", overflowY: "hidden" } : { display: "none", overflowY: "hidden" }}
      >
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Farm
              </h5>
              <button type="button" className="btn-close" onClick={closeStakeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body p-2">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display font-semibold text-jacarta-700 dark:text-white">Amount</span>
              </div>
              <div className="relative mb-2 flex items-center overflow-hidden">
                <input
                  type="text"
                  className="h-12 w-full flex-[3] focus:ring-accent text-white shadow-accent-volume"
                  placeholder="0"
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) setAmount(Number(e.target.value));
                  }}
                />
                <div className="flex flex-1 justify-center self-stretch border-l border-jacarta-100 bg-jacarta-50 shadow-accent-volume bg-accent hover:bg-accent-dark text-white cursor-pointer max">
                  <span className="self-center px-2 " onClick={() => setAmount(selectedTokenBalance)}>
                    Max
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Balance:</span>
                </div>
                <div className="text-right">
                  <span className="text-white">
                    {selectedTokenBalance ? selectedTokenBalance : 0} {selectedToken?.symbol}
                  </span>
                </div>
              </div>
              <div className="dropdown my-1 cursor-pointer">
                <div className="sm:col-span-3 w-full">
                  <div className="mb-2 mt-4 flex items-center justify-between">
                    <span className="font-display font-semibold text-jacarta-700 dark:text-white">Lock Time : </span>
                  </div>
                  <div className="relative mb-2 flex items-center overflow-hidden">
                    <select
                      id="country"
                      name="country"
                      className="h-12 w-full flex-[3] text-white shadow-accent-volume"
                      onChange={(e) => setSelecteMonth(Number(e.target.value))}
                    >
                      <option value={0}>-Select-</option>
                      {selectedToken?.apy?.length
                        ? selectedToken?.apy.map((item) => {
                            return item.apyper ? (
                              <option value={item.month}>
                                {item.month} months ({item.apyper}% APY)
                              </option>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  {/* <p className="dark:text-jacarta-300">
                    Once you've set up your wallet of choice, connect it to OpenSeaby clicking the NFT Marketplacein the top right corner.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="modal-body p-5 pb-2">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display font-semibold text-jacarta-700 dark:text-white">Reward Info :</span>
              </div>
              {Number(selecteMonth) > 0 && Number(amount) > 0 ? (
                rewardInfoSpinner ? (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between">
                      <div className="text-left">
                        <span className="text-white">Daily Percentage :</span>
                      </div>
                      <div className="text-right">
                        <span className="text-white">{rewardInfo?.withdrawalDailyPer} %</span>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-left">
                        <span className="text-white">Daily Reward :</span>
                      </div>
                      <div className="text-right">
                        <span className="text-white">{rewardInfo?.withdrawalDailyReward} OMP</span>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <p className="text-danger text-center" style={{ color: "#ff3333" }}>
                  Select moth and enter an amount to view Reward information.
                </p>
              )}
              <hr className="my-2" />
            </div>
            {/* end body */}
            {hash ? (
              <div className="flex justify-center" style={{ color: "#28a745" }}>
                <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${hash}`} target="_blank">
                  View {formatAddress(hash)}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="modal-footer p-3">
              <div className="flex items-center justify-center space-x-4">
                {spinner ? (
                  <Spinner />
                ) : (
                  <button
                    type="button"
                    className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={() => stake()}
                    disabled={btnDisabled ? true : selectedTokenBalance > 0 ? false : true}
                  >
                    Farm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class={stakeModal ? "modal-backdrop fade show" : ""}></div>
    </>
  );
}
