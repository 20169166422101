import { useState, useEffect, useContext } from "react";
import { onmaxContext } from "../../../OnmaxContext";
import React from "react";
import Web3 from "web3";
import Tokens from "../../Comman/Tokens.json";
import Copy from "../../Comman/Copy";
import Spinner from "../../Comman/Spinner";

const UnstakesFixed = () => {
  const {
    getWeb3ContractFixed,
    connAddress,
    formatAddress,
    formatDate,
    // getBalanceBNB,
    getEtherContractFixed,
    toastSuccess,
    toastError,
    giveAllowance,
    checkAllowance,
    setJoiningDate,
    getFullMonthName,
  } = useContext(onmaxContext);

  const [stakings, setStakings] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [stakeSpinner, setStakeSpinner] = useState(false);
  const [withSpinner, setWithSpinner] = useState(false);
  const [dataSpinner, setDataSpinner] = useState(true);

  const [withdrawalModal, setWithdrawalModal] = useState(false);
  const [stakeModal, setStakeModal] = useState(false);
  const [selectedStake, setSelectedStake] = useState(null);
  const [rewardInfo, setRewardInfo] = useState({
    withdrawalDays: 0,
    withdrawalDailyPer: 0,
    withdrawalDailyReward: 0,
    withdrawalTotalReward: 0,
  });

  const [hash, setHash] = useState("");

  const getWithdrawalData = async () => {
    try {
      const contract = await getWeb3ContractFixed();
      const data = await contract.methods.getFarmingRewardInfo(selectedStake?.id).call();
      setRewardInfo({
        withdrawalDays: data.day,
        withdrawalDailyPer: Number(Web3.utils.fromWei(data.dailyPer, "ether")).toFixed(6),
        withdrawalDailyReward: Number(Web3.utils.fromWei(data.dailyReward, "ether")).toFixed(6),
        withdrawalTotalReward: Number(Web3.utils.fromWei(data.totalReward, "ether")).toFixed(6),
      });
    } catch (err) {
      console.log("error in getWtihdrawalData : ", err);
    }
  };

  useEffect(() => {
    if (selectedStake) {
      getWithdrawalData();
    }
  }, [selectedStake]);

  const openModalWithData = (data) => {
    setSelectedStake(data);
    setWithdrawalModal(true);
  };

  const closeWithdrawalModal = () => {
    setSelectedStake(null);
    setRewardInfo({
      withdrawalDays: 0,
      withdrawalDailyPer: 0,
      withdrawalDailyReward: 0,
      withdrawalTotalReward: 0,
    });
    setWithdrawalModal(false);
    setHash("");
  };

  const openModalStakeData = (data) => {
    setSelectedStake(data);
    setStakeModal(true);
  };

  const closeStakeModal = () => {
    setSelectedStake(null);
    setStakeModal(false);
  };

  const getStaking = async () => {
    try {
      setDataSpinner(true);
      const contract = await getWeb3ContractFixed();
      const stakArr = await contract.methods.getFarmingOfAddress(connAddress).call();
      await stakArr.reverse();
      let stakArrCpy = [];
      for (let i = 0; i < stakArr.length; i++) {
        if (Number(stakArr[i].endtime) > 0) {
          stakArrCpy.push(stakArr[i]);
        }
      }
      setStakings([...stakArrCpy]);
      setDataSpinner(false);
      setTokenDetails(stakArrCpy);
    } catch (err) {
      console.log("error in getStaking : ", err);
    }
  };

  const setTokenDetails = (stakArr) => {
    try {
      if (Tokens?.length) {
        let stakArrCpy = [...stakArr];
        let newStakArr = [];
        for (let i = 0; i < stakArrCpy.length; i++) {
          const findtoken = Tokens.find((item) => item.address.toLowerCase() === stakArrCpy[i].token.toLowerCase());
          const withdrawalDiff = Number(stakArrCpy[i].lastClaimed) + 60 * 60 * 24 - new Date().getTime() / 1000;
          const unstakeDiff =
            Number(stakArrCpy[i].starttime) + Number(stakArrCpy[i].lockFor) * 30 * 24 * 60 * 60 - new Date().getTime() / 1000;
          newStakArr.push({
            endtime: stakArrCpy[i].endtime,
            id: stakArrCpy[i].id,
            lastClaimed: stakArrCpy[i].lastClaimed,
            lockFor: stakArrCpy[i].lockFor,
            starttime: stakArrCpy[i].starttime,
            status: stakArrCpy[i].status,
            token: stakArrCpy[i].token,
            user: stakArrCpy[i].user,
            value: stakArrCpy[i].value,
            icon: findtoken.icon,
            symbol: findtoken.symbol,
            withdrawalDiff: withdrawalDiff,
            unstakeDiff: unstakeDiff,
            withdrawalTimeLeft: `${Math.floor(withdrawalDiff / (60 * 60))
              .toString()
              .padStart(2, "0")}H : ${Math.floor((withdrawalDiff / 60) % 60)
              .toString()
              .padStart(2, "0")}M`,
            unstakeTimeLeft: `${Math.floor(unstakeDiff / (60 * 60 * 24))
              .toString()
              .padStart(2, "0")}D : ${Math.floor((unstakeDiff / (60 * 60)) % 24)
              .toString()
              .padStart(2, "0")}H : ${Math.floor((unstakeDiff / 60) % 60)
              .toString()
              .padStart(2, "0")}M`,
            withdrawalBtnDisabled: Number(stakArrCpy[i].lastClaimed) * 1000 + 60 * 60 * 24 * 1000 > new Date().getTime() ? true : false,
            stakeBtnDisabled:
              Number(stakArrCpy[i].starttime) * 1000 + Number(stakArrCpy[i].lockFor) * 30 * 24 * 60 * 60 * 1000 > new Date().getTime()
                ? true
                : false,
          });
        }
        setStakings([...newStakArr]);
      }
    } catch (err) {
      console.log("error in setTokenDetails : ", err);
    }
  };

  useEffect(() => {
    if (connAddress) {
      getStaking();
    }
  }, [connAddress]);

  const unstake = async (item) => {
    try {
      if (item) {
        setStakeSpinner(true);
        let temp = true;
        const contract = await getEtherContractFixed();
        if (temp) {
          const tx = await contract.unfarm(item.id);
          let recipt = await tx.wait();
          if (recipt?.transactionHash) {
            setHash(recipt?.transactionHash);
            toastSuccess("Unfarm successfully.");
            // getBalanceBNB();
            getStaking();
            setStakeSpinner(false);
          }
        }
      } else {
        toastError("Data not found.");
        setStakeSpinner(false);
      }
    } catch (err) {
      console.log("error in stake : ", err);
      toastError("Something went wrong.");
      setStakeSpinner(false);
    }
  };

  const withdrawals = async (item) => {
    try {
      if (item) {
        setWithSpinner(true);
        let temp = true;
        const contract = await getEtherContractFixed();
        const val = await Number(Web3.utils.fromWei(item.value, "ether"));
        const res = await checkAllowance(item.token, connAddress, process.env.REACT_APP_CONTRACT_FIXED, val);
        if (!res) {
          const data = await giveAllowance(item.token, process.env.REACT_APP_CONTRACT_FIXED);
          if (!data) {
            temp = false;
            toastError("Something went wrong.");
            setWithSpinner(false);
          }
        }
        // const res1 = await checkAllowance(Tokens[0]?.address, connAddress, process.env.REACT_APP_CONTRACT_FIXED, val);
        // if (!res1) {
        //   const data = await giveAllowance(Tokens[0]?.address, process.env.REACT_APP_CONTRACT_FIXED);
        //   if (!data) {
        //     temp = false;
        //     toastError("Something went wrong.");
        //     setWithSpinner(false);
        //   }
        // }
        if (temp) {
          const tx = await contract.harvest(item.id);
          let recipt = await tx.wait();
          if (recipt?.transactionHash) {
            setHash(recipt?.transactionHash);
            getWithdrawalData();
            toastSuccess("Harvest successfully.");
            // getBalanceBNB();
            getStaking();
            setWithSpinner(false);
          }
        }
      } else {
        toastError("Data not found.");
        setWithSpinner(false);
      }
    } catch (err) {
      console.log("error in stake : ", err);
      toastError("Something went wrong.");
      setWithSpinner(false);
    }
  };

  return (
    <>
      {dataSpinner ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
          {stakings?.length ? (
            stakings?.map((item, index) => {
              let val = Web3.utils.fromWei(item.value, "ether");
              return (
                <article key={index}>
                  <div className="block border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                    <figure className="relative">
                      <div className="sm:w-1/2 sm:pr-4 lg:pr-8">
                        <div className="mt-3 flex">
                          <figure className="mr-4 shrink-0">
                            <a className="relative block">
                              <img src={item.icon} width="60" alt="avatar" className="rounded-2lg" loading="lazy" />
                            </a>
                          </figure>
                          <div>
                            <div className="flex items-center whitespace-nowrap">
                              <span className="text-lg font-medium leading-tight tracking-tight text-green">
                                {val} {item.symbol}
                              </span>
                            </div>
                            <span className="text-sm text-jacarta-400 dark:text-jacarta-300 flex">
                              {formatAddress(item.token)} <Copy text={item.token} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </figure>
                    <div className="mt-3 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">Lock Time : {Number(item.lockFor)} months</span>
                    </div>
                    <div className="mt-1 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">
                        Date : {formatDate(Number(item.starttime) * 1000)}
                      </span>
                    </div>
                    <div className="mt-1 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">
                        End Date : {formatDate(Number(item.starttime) * 1000 + Number(item.lockFor) * 30 * 24 * 60 * 60 * 1000)}
                      </span>
                    </div>
                    <hr className="my-2" />

                    <div className="mt-1 text-sm">
                      <span className="mr-1 text-jacarta-700 dark:text-jacarta-200">
                        <b>Note :</b> You can claim your farm amount after 1 day of unstake date along with harvest amount.
                      </span>
                    </div>
                    <hr className="my-2" />
                    {item.status === true ? (
                      <>
                        <div className="mt-8 flex items-center justify-between">
                          <button
                            className="inline-block w-full  bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                            // disabled={spinner ? true : item.withdrawalBtnDisabled ? true : false}
                            onClick={() => openModalWithData(item)}
                          >
                            Harvest
                          </button>
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          {Number(item.endtime) <= 0 ? (
                            <button
                              className="inline-block w-full  bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                              onClick={() => openModalStakeData(item)}
                              // disabled={spinner ? true : item.stakeBtnDisabled ? true : false}
                            >
                              Unfarm
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <h3 className="font-semibold text-center" style={{ color: "#28a745" }}>
                        Completed!
                      </h3>
                    )}
                  </div>
                </article>
              );
            })
          ) : (
            <>
              <h2 className="text-center text-white"></h2>
              <h2 className="text-center text-white">No data found.</h2>
              <h2 className="text-center text-white"></h2>
            </>
          )}
          <div
            className={`modal fade ${
              spinner ? "show" : ""
            } block border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700`}
            tabIndex={-1}
            style={spinner ? { display: "block", overflowY: "hidden" } : { display: "none", overflowY: "hidden" }}
          >
            <div className="modal-dialog max-w-2xl">
              <div className="modal-content">
                <div className="modal-body p-6">
                  <div className="flex items-center justify-center space-x-4">
                    <Spinner />
                  </div>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
          <div class={spinner ? "modal-backdrop fade show" : ""}></div>
        </div>
      )}
      <div
        className={`modal fade ${
          withdrawalModal ? "show" : ""
        } block border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700`}
        id="placeBidModal"
        tabIndex={-1}
        aria-labelledby="placeBidLabel"
        style={withdrawalModal ? { display: "block", overflowY: "hidden" } : { display: "none", overflowY: "hidden" }}
      >
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Harvest
              </h5>
              <button type="button" className="btn-close" onClick={closeWithdrawalModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display font-semibold text-jacarta-700 dark:text-white">Reward Info</span>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Days :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{rewardInfo?.withdrawalDays ? Number(rewardInfo?.withdrawalDays) : 0} days</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Daily Percentage :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{rewardInfo?.withdrawalDays ? Number(rewardInfo?.withdrawalDailyPer) : 0} %</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Daily Reward :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{rewardInfo?.withdrawalDays ? Number(rewardInfo?.withdrawalDailyReward) : 0} OMP</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Total Reward :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{rewardInfo?.withdrawalDays ? Number(rewardInfo?.withdrawalTotalReward) : 0} OMP</span>
                </div>
              </div>
              <hr className="my-2" />
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Harvest after :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{selectedStake?.withdrawalDiff > 0 ? selectedStake?.withdrawalTimeLeft : "00H : 00M"}</span>
                </div>
              </div>
            </div>
            {/* end body */}
            {hash ? (
              <div className="flex justify-center" style={{ color: "#28a745" }}>
                <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${hash}`} target="_blank">
                  View {formatAddress(hash)}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {withSpinner ? (
                  <Spinner />
                ) : (
                  <button
                    type="button"
                    className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={() => withdrawals(selectedStake)}
                    disabled={selectedStake?.withdrawalBtnDisabled ? selectedStake?.withdrawalBtnDisabled : true}
                  >
                    Harvest
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class={withdrawalModal ? "modal-backdrop fade show" : ""}></div>
      <div
        className={`modal fade ${
          stakeModal ? "show" : ""
        } block border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700`}
        id="placeBidModal"
        tabIndex={-1}
        aria-labelledby="placeBidLabel"
        style={stakeModal ? { display: "block", overflowY: "hidden" } : { display: "none", overflowY: "hidden" }}
      >
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Unfarm
              </h5>
              <button type="button" className="btn-close" onClick={closeStakeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body p-6">
              <div className="flex justify-between">
                <div className="text-left">
                  <span className="text-white">Unfarm after :</span>
                </div>
                <div className="text-right">
                  <span className="text-white">{selectedStake?.unstakeDiff > 0 ? selectedStake?.unstakeTimeLeft : "00D : 00H : 00M"}</span>
                </div>
              </div>
            </div>
            {/* end body */}
            {hash ? (
              <div className="flex justify-center" style={{ color: "#28a745" }}>
                <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${hash}`} target="_blank">
                  View {formatAddress(hash)}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {stakeSpinner ? (
                  <Spinner />
                ) : (
                  <button
                    type="button"
                    className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={() => unstake(selectedStake)}
                    disabled={selectedStake?.stakeBtnDisabled ? selectedStake?.stakeBtnDisabled : true}
                  >
                    Unfarm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class={stakeModal ? "modal-backdrop fade show" : ""}></div>
    </>
  );
};

export default UnstakesFixed;
